//OurWineMakersNote.js file

import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { Link } from 'gatsby'

import { isElementInViewport } from '../../../components/functions'

export const OurWineMakersNote = ({ slice }) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    //create an event listener
    useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    const data = slice.primary;

    const shopClickedDatalayer = (target, url) => {
        // Shop now clicked data layer event - "Shop" or "Shop Now" that direcs to WineDirect store
        var dl = {
            "event": "shop_now_clicked",
            "brand_name": "offshore",
            "product_name": "",
            "referral": "brand page",
            "page_location": window.location.href
        }

        if(target === null || target === "_self"){
            dl.eventCallback = function(){
                window.location = url;
            }
        }

        //Data layer - Shop now clicked
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(dl)
    }

    return(
        <section
            className={(isInView)? 'wine-makers-note d-flex justify-content-center align-items-center in-view position-relative' : 'wine-makers-note d-flex justify-content-center align-items-center position-relative'}
            ref={ref}
            >
            <div className='container slide-up position-relative container-fluid text-center '>
                <div className='row d-flex flex-column align-items-center'>
                    <div className='col-lg-6'>
                        {(data.section_title.text)?
                            <h3 className="pb-3 text-uppercase">{data.section_title.text}</h3>
                        : null}
                    </div>
                    <div className='col-lg-6'>
                        {(data.section_description.html)?
                            <div className="desc" dangerouslySetInnerHTML={{ __html: data.section_description.html }}/>
                        :null}
                        {(data.section_button_link.url && data.section_button_text.text)?
                            (data.section_button_link.link_type === "Document" && data.section_button_link.uid)?
                            <Link to={"/" + data.section_button_link.uid}>
                                <button className='btn btn-primary-2 m-0'>{data.section_button_text.text}</button>
                            </Link>
                            :
                            <a 
                                href={data.section_button_link.url} 
                                target={(data.section_button_link.target)? data.section_button_link.target : "_self"} 
                                className="mobile-only"
                                onClick={(e) => {
                                    if(data.section_button_link.target === null || data.section_button_link.target === "_self"){
                                        e.preventDefault();
                                    }
                                    shopClickedDatalayer(data.section_button_link.target, data.section_button_link.url)
                                }} 
                                onKeyDown={(e) => {
                                    if(data.section_button_link.target === null || data.section_button_link.target === "_self"){
                                        e.preventDefault();
                                    }
                                    shopClickedDatalayer(data.section_button_link.target, data.section_button_link.url)
                                }}
                            >
                                <button className='btn btn-primary-2 mb-0 mt-0'>{data.section_button_text.text}</button>
                            </a>
                        :null}
                    </div>
                </div>
            </div>
        </section>
    )
}